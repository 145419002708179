import React, { ReactElement, useState } from "react";
import {
  Badge,
  Box,
  Collapse,
  Flex,
  Heading,
  Link,
  Text,
  useMediaQuery,
  useTheme,
} from "@springcare/sh-component-library";
import HiddenContent from "./HiddenContent";

interface AccordionWithBulletsProps {
  LeftIcon: React.ElementType;
  title: string;
  subtitle: string;
  bullets: string[];
  endText: string;
  ctaText: string;
  onAccordionAction: () => void;
  onCTAClick: () => void;
}

const AccordionWithBullets = ({
  LeftIcon,
  title,
  subtitle,
  bullets,
  endText,
  ctaText,
  onAccordionAction,
  onCTAClick,
}: AccordionWithBulletsProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);

  const { breakpoints } = useTheme();
  const [isMobile] = useMediaQuery(`(max-width: ${breakpoints.sm})`);

  // TODO: add 'less/more' button to translations when adding translations
  // TODO: add 'New' to translations when adding translations
  return (
    <Box
      bg="#FBFAF9"
      borderRadius={16}
      p={24}
      mt={50}
      onClick={() => {
        if (isMobile) {
          onAccordionAction();
        }
      }}
      cursor={isMobile ? "pointer" : "default"}
    >
      <Flex direction={isMobile ? "column" : "row"}>
        <Flex justifyContent={isMobile ? "space-between" : "flex-start"}>
          <LeftIcon width={80} height={80} aria-hidden={true} />
          {isMobile && (
            <Box>
              <Badge colorScheme="base">New</Badge>
            </Box>
          )}
        </Flex>
        <Box ps={isMobile ? 0 : 24} mt={isMobile ? 16 : 0}>
          {!isMobile && (
            <Box mb={4}>
              <Badge colorScheme="base">New</Badge>
            </Box>
          )}
          <Heading as="h2" fontSize={21} fontWeight={600}>
            {title}
          </Heading>
          <Text fontSize={16} mt={1}>
            {subtitle}
            {!isMobile && (
              <>
                {" ... "}
                <Link
                  as={"button"}
                  color="medium-emphasis"
                  onClick={() => {
                    if (!isOpen) {
                      onAccordionAction();
                    }

                    setIsOpen((prev) => !prev);
                  }}
                >
                  {isOpen ? "less" : "more"}
                </Link>
              </>
            )}
          </Text>
          <Collapse in={isOpen && !isMobile} animateOpacity>
            <HiddenContent
              bullets={bullets}
              endText={endText}
              ctaText={ctaText}
              onCTAClick={onCTAClick}
            />
          </Collapse>
        </Box>
      </Flex>
    </Box>
  );
};

export default AccordionWithBullets;

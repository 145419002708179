import { useEffect } from "react";
import {
  LiveKitRoom,
  RoomAudioRenderer,
  LiveKitRoomProps,
} from "@livekit/components-react";
import { Box, useMediaQuery } from "@springcare/sh-component-library";
import ParticipantPermissionsChecker from "components/templates/SHSessionRoom/components/ParticipantPermissionsChecker";
import SessionUnavailableView from "components/templates/SHSessionRoom/views/SessionUnavailableView";
import PreJoin from "components/templates/SHSessionRoom/views/PreJoin";
import SessionRoomStage from "components/templates/SHSessionRoom/components/SessionRoomStage";
import SessionEndedMember from "components/templates/VirtualSessionView/components/EndSessionView";
import SessionEndedProvider from "components/templates/SHSessionRoom/views/SessionEndedProvider";
import {
  useSessionViewStatus,
  useSessionConnectStatus,
  useSessionParticipantType,
} from "context/SessionRoomContext";
import { ParticipantType } from "components/templates/SHSessionRoom/types";

const TypedLiveKitRoom = LiveKitRoom as React.ComponentType<LiveKitRoomProps>;

// NOTE: _loading and _error should pass lint while unused until future
const SHSessionRoom = ({
  appointmentId,
  sessionData,
  loading: _loading,
  error: _error,
}) => {
  const { sessionViewStatus, setSessionViewStatus, SessionViewStatus } =
    useSessionViewStatus();
  const { shouldConnect, isConnected, setIsConnected, handleDisconnect } =
    useSessionConnectStatus();

  const [isMobile] = useMediaQuery("(max-width: 450px)");

  const {
    livekit_url,
    jwt,
    join_window_start,
    join_window_end,
    participant_type,
  } = sessionData;

  const { setParticipantType } = useSessionParticipantType();
  setParticipantType(participant_type);

  useEffect(() => {
    // NOTE: This function checks if the session is too early or too late
    const now = new Date();
    const sessionStartWindow = new Date(join_window_start);
    const sessionEndWindow = new Date(join_window_end);

    if (now < sessionStartWindow) {
      setSessionViewStatus(SessionViewStatus.TooEarly);
    } else if (now > sessionEndWindow) {
      setSessionViewStatus(SessionViewStatus.TooLate);
    }
  }, []);

  // NOTE: These are the relevant states of the Session lifecycle
  const SESSION_UNAVAILABLE =
    sessionViewStatus === SessionViewStatus.TooEarly ||
    sessionViewStatus === SessionViewStatus.TooLate;

  const PREJOIN_ROOM =
    sessionViewStatus === SessionViewStatus.NotStarted ||
    sessionViewStatus === SessionViewStatus.MemberIsWaiting ||
    sessionViewStatus === SessionViewStatus.ProviderNoShow ||
    sessionViewStatus === SessionViewStatus.Connecting;

  const SESSION_IN_PROGRESS =
    isConnected && sessionViewStatus === SessionViewStatus.InProgress;

  const SESSION_ENDED = sessionViewStatus === SessionViewStatus.Ended;
  const SESSION_ENDED_MEMBER =
    SESSION_ENDED && participant_type === ParticipantType.Member;
  const SESSION_ENDED_PROVIDER =
    SESSION_ENDED && participant_type === ParticipantType.Provider;

  return (
    <>
      {SESSION_UNAVAILABLE && (
        <SessionUnavailableView sessionData={sessionData} isMobile={isMobile} />
      )}

      {jwt && livekit_url && !SESSION_UNAVAILABLE && (
        <TypedLiveKitRoom
          data-lk-theme="default"
          token={jwt}
          serverUrl={livekit_url}
          connect={shouldConnect}
          onConnected={() => setIsConnected(true)}
          onDisconnected={handleDisconnect}
        >
          <ParticipantPermissionsChecker />

          {PREJOIN_ROOM && (
            <PreJoin sessionData={sessionData} isMobile={isMobile} />
          )}

          {SESSION_IN_PROGRESS && (
            <Box margin="0 auto" h="100vh" bg="#000" role="main">
              <SessionRoomStage isMobile={isMobile} />
              <RoomAudioRenderer />
            </Box>
          )}
        </TypedLiveKitRoom>
      )}
      {SESSION_ENDED_MEMBER && !isConnected && (
        <SessionEndedMember appointmentId={appointmentId} />
      )}
      {SESSION_ENDED_PROVIDER && !isConnected && (
        <Box role="main">
          <SessionEndedProvider />
        </Box>
      )}
    </>
  );
};

export default SHSessionRoom;

import {
  Heading as SHHeading,
  Box,
  Divider,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Spinner,
  useMediaQuery,
  useTheme,
} from "@springcare/sh-component-library";
import { TRACK_EVENT } from "utils/mixpanel";
import routes from "routes";
import {
  YourCareTab,
  FindYourCareTab,
} from "components/templates/CareVisitsPage/components/Tabs";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { CareContextValues } from "pages/apps/MemberDashboard/care_visits";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { TabHeader } from "./components/Tabs/TabHeader";
import { ResourcesTab } from "./components/Tabs/ResourcesTab";
import { isCNScheduling } from "utils/global";

export const CarePage = () => {
  const { t } = useTranslation("careVisits");
  const { breakpoints } = useTheme();
  const [isMobileSize] = useMediaQuery(`(max-width: ${breakpoints.sm})`);

  const hasLocalResourcesEnabled = useFeatureFlag(FLAGS.SDOH_LOCAL_RESOURCES);
  const hasConnectedBenefitsEnabled = useFeatureFlag(
    FLAGS.SHOW_CONNECTED_BENEFITS,
  );

  const pageHeading = t("careProvider.myCare");
  const yourCareTabHeading = t("yourCareTab.title");
  const findNewCareTabHeading = t("findNewCareTab.title");
  const resourcesTabHeading = t("resourcesTab.title");

  const {
    setIsFindNewCareTab,
    currentTab,
    setCurrentTab,
    determinedInitialTab,
    memberInfo,
  } = useContext(CareContextValues);

  const isCnScheduling = isCNScheduling(
    memberInfo?.user?.member?.cohort?.contract_term?.scheduling_access?.name,
  );

  if (!determinedInitialTab) {
    return <Spinner speed="1s" size="xl" />;
  }

  return (
    <div role="main" data-testid="care-page">
      <Box ml={[0, 20, 20, 40]}>
        <SHHeading
          mb={24}
          as="h1"
          color="content-primary"
          data-cy={"headline-text"}
          size="heading-large"
        >
          {pageHeading}
        </SHHeading>
        <Tabs variant="soft-rounded" index={currentTab}>
          <TabList mb={24}>
            <TabHeader
              heading={yourCareTabHeading}
              onClick={() => {
                TRACK_EVENT.BUTTON_CLICKED(
                  routes.MemberCareVisits.as,
                  "Your Care Tab",
                );
                setIsFindNewCareTab(false);
                setCurrentTab(0);
              }}
              testId="your-care-tab"
            />
            {!isCnScheduling && (
              <TabHeader
                heading={findNewCareTabHeading}
                onClick={() => {
                  TRACK_EVENT.BUTTON_CLICKED(
                    routes.MemberCareVisits.as,
                    "Find New Care Tab",
                  );
                  setIsFindNewCareTab(true);
                  setCurrentTab(1);
                }}
                testId="find-new-care-tab"
              />
            )}
            {(hasLocalResourcesEnabled || hasConnectedBenefitsEnabled) && (
              <TabHeader
                heading={resourcesTabHeading}
                onClick={() => {
                  TRACK_EVENT.BUTTON_CLICKED(
                    routes.MemberCareVisits.as,
                    "Resources Tab",
                  );
                  setIsFindNewCareTab(false);
                  setCurrentTab(2);
                }}
                testId="resources-tab"
              />
            )}
          </TabList>
          <Divider color="border-subtle" mb={40} w="100%" />
          <TabPanels>
            <TabPanel>
              <YourCareTab />
            </TabPanel>
            {!isCnScheduling && (
              <TabPanel align={isMobileSize ? "center" : null}>
                <FindYourCareTab />
              </TabPanel>
            )}
            {(hasLocalResourcesEnabled || hasConnectedBenefitsEnabled) && (
              <TabPanel>
                <ResourcesTab />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </Box>
    </div>
  );
};

import React, { useCallback } from "react";
import { connect } from "react-redux";
import { useMutation } from "@apollo/client";
import Router from "next/router";
import { createCheckIn } from "operations/mutations/assessment";
import { createSUDZendeskTicket } from "operations/mutations/member";
import routes from "routes";

import { useTranslation } from "react-i18next";

import {
  Box,
  Flex,
  Heading,
  Spinner,
  MobileQrCode,
  useDisclosure,
  useBreakpointValue,
} from "design-system/components";
import { TRACK_EVENT } from "utils/mixpanel";
import { AlsoAvailableTile } from "components";
import { Carousel } from "components/molecules";
import {
  useAllUpcomingAppointmentSlots,
  useCareInfoFlyoutDisclosures,
} from "hooks";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
// NOTE: Eventually we want to refactor so we don't use Smeargle/Redux, but due to time constraints, it's faster to recycle working code
import { openModal, addNotification } from "@spring/smeargle/actions";
import { AppointmentKind } from "@spring/constants";
import CareNavigatorAvailabilityModal from "components/modals/CareNavigatorScheduleModal";
import {
  getAlsoAvailableSectionConfigs,
  getAvailableItemsWithPositionedCoachingCard,
  closeDownloadMobileModal,
} from "./AlsoAvailableSectionConfig.utils";
import { DOWNLOAD_APP_URL } from "shared/utils/downloadMobileApp.utils";
import {
  CareConsultantFlyout,
  CareNavigationFlyout,
  CoachingFlyout,
  CouplesTherapyFlyout,
  MedicationManagementFlyout,
  SpecializedCareNavigationFlyout,
  SubstanceUseFlyout,
  TherapyFlyout,
  TobaccoCessationFlyout,
} from "components/organisms/CareInfoFlyout/components";
import { getIterableCampaignInfo } from "utils/localStorage";
import { useNeurodiversityPagesAccess } from "modules/MemberDashboard/Neurodiversity/hooks/useNeurodiversityPagesAccess.ts";

const AlsoAvailableSection = ({
  availableCareItems,
  careTeam,
  customerId,
  memberId,
  openModal,
  sessionSnapshot,
  isDirectScheduling,
  addNotification,
  showGlobalExperience,
  tobaccoCtaCallBack,
  hasManagedDependents,
  onScheduleModalOpen,
  openSpecializedCNScheduleModal,
  isHealthPlan,
}) => {
  const { t, i18n } = useTranslation([
    "limitedLangBenefits",
    "benefits",
    "carePlan",
  ]);
  const couplesTherapyFlag = useFeatureFlag(FLAGS.ENABLE_COUPLES_THERAPY);
  const { canAccessNeurodiversityPage } = useNeurodiversityPagesAccess();
  const careNavigator = careTeam?.user?.member?.care_team?.care_navigator;
  const { visits_covered_coaching } = sessionSnapshot;
  const isPaidMember =
    useFeatureFlag(FLAGS.COACHING_AVAILABLE_SECTION) &&
    visits_covered_coaching !== null &&
    visits_covered_coaching >= 0;
  const { data: upcomingCareNavigatorAppointmentSlots } =
    useAllUpcomingAppointmentSlots(
      careNavigator,
      AppointmentKind.FollowUpCareNavigation,
      1,
      true,
      false,
    );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const showSudProgramPage = useFeatureFlag(FLAGS.SHOW_SUD_PROGRAM_PAGE);
  const {
    isOpen: isCNScheduleModalOpen,
    onOpen: openCNScheduleModal,
    onClose: closeCNScheduleModal,
  } = useDisclosure();
  const disclosures = useCareInfoFlyoutDisclosures();

  const [createCheckInMutation] = useMutation(createCheckIn, {
    variables: {
      member_id: memberId,
      campaign: getIterableCampaignInfo(),
    },
    skip: !memberId,
    onCompleted: ({ createCheckIn }) =>
      handleCheckInMutationComplete(createCheckIn),
  });

  const [sudZendeskMutation] = useMutation(createSUDZendeskTicket, {
    onCompleted: () => {
      disclosures.substanceUse.onClose();
      window.localStorage.setItem("sud_requested", true);

      addNotification(
        t("homepage:alsoAvailableSection.substanceUse.successNotification"),
        "success",
      );
    },
    onError: () => {
      disclosures.substanceUse.onClose();

      addNotification(
        t("homepage:alsoAvailableSection.substanceUse.errorNotification"),
        "error",
      );
    },
  });

  function handleCheckInMutationComplete(createCheckIn) {
    const { id } = createCheckIn.assessment;
    let { to, as } = routes.TakeMemberAssessment;
    as = as.replace(":id", id);
    TRACK_EVENT.CARD_CLICKED(
      window.location.pathname,
      as,
      alsoAvailableSectionConfig.check_in.heading,
      {
        spring_doc_id: "HPR_021",
        location: "Also available to you section",
      },
    );
    Router.replace(
      {
        pathname: to,
        query: { id },
      },
      as,
    );
  }

  const alsoAvailableSectionConfig = getAlsoAvailableSectionConfigs({
    t,
    disclosures,
    showSudProgramPage,
    showGlobalExperience,
    createCheckInMutation,
    onOpen,
    onScheduleModalOpen,
    hasManagedDependents,
    customerId,
    i18n,
  });

  const coachingAvailableSectionFlag = useFeatureFlag(
    FLAGS.COACHING_AVAILABLE_SECTION,
  );

  const availableItems = availableCareItems
    ? [
        couplesTherapyFlag && { care_item_name: "couples_therapy" },
        // IMPORTANT: The neurodiversity pages should NOT be accessible in the prod env.
        // They are vaporware and are only intended for our sales team to use in the demo env.
        // See https://springhealth.atlassian.net/browse/MXE-520 for more detail.
        canAccessNeurodiversityPage && {
          care_item_name: "neurodiversity_support",
        },
        ...getAvailableItemsWithPositionedCoachingCard(
          availableCareItems,
          isPaidMember,
          coachingAvailableSectionFlag,
        ),
      ].filter((val) => val)
    : [];

  // TODO: figure out if the state that we're opening this modal in is even up to spec????
  const handleFlyoutOpenCNAppointmentModal = useCallback(() => {
    // Close CN Info Flyout first before opening the other modal
    // TODO: A1ly CONSIDERATION: figure out how to force focus on the CN Scheduling modal on Info Flyout close
    if (careNavigator) {
      disclosures.careNavigation.onClose();
      Router.push(routes.CareNavigation.as);
    }
  }, [
    careNavigator,
    disclosures.careNavigation,
    openCNScheduleModal,
    openModal,
    t,
    upcomingCareNavigatorAppointmentSlots,
  ]);

  const handleFlyoutOpenSpecializedCNAppointmentModal = useCallback(() => {
    disclosures.specializedCareNavigation.onClose();
    openSpecializedCNScheduleModal();
  }, [disclosures.specializedCareNavigation, openSpecializedCNScheduleModal]);

  const handleCoachingFlyout = useCallback(() => {
    disclosures.coaching.onClose();
    handleFlyoutOpenCNAppointmentModal();
  }, [disclosures.coaching, handleFlyoutOpenCNAppointmentModal]);

  const carouselHeadingVariant = useBreakpointValue([
    "md_v1",
    "md_v1",
    "lg_v1",
    "lg_v1",
  ]);

  const CarouselHeading = () => (
    <Heading as="h2" variant={carouselHeadingVariant}>
      {t("homepage:alsoAvailableSection.heading")}
    </Heading>
  );

  const flyoutMap = {
    care_consultant_scheduling: {
      component: CareConsultantFlyout,
      props: {
        isOpen: disclosures.careConsultant.isOpen,
        onClose: disclosures.careConsultant.onClose,
        customerId,
        i18n,
      },
    },
    care_navigator_scheduling: {
      component: CareNavigationFlyout,
      props: {
        callback: handleFlyoutOpenCNAppointmentModal,
        hasManagedDependents,
        isAlsoAvailableTile: true,
        isOpen: disclosures.careNavigation.isOpen,
        onClose: disclosures.careNavigation.onClose,
        showGlobalExperience,
      },
    },
    coach_assignment: {
      component: CoachingFlyout,
      props: {
        callback: isDirectScheduling ? null : handleCoachingFlyout,
        isAlsoAvailableTile: true,
        isOpen: disclosures.coaching.isOpen,
        onClose: disclosures.coaching.onClose,
      },
    },
    couples_therapy: {
      component: CouplesTherapyFlyout,
      props: {
        isAlsoAvailableTile: true,
        isOpen: disclosures.couplesTherapy.isOpen,
        onClose: disclosures.couplesTherapy.onClose,
      },
    },
    medication_manager_assignment: {
      component: MedicationManagementFlyout,
      props: {
        isAlsoAvailableTile: true,
        isOpen: disclosures.medicationManagement.isOpen,
        onClose: disclosures.medicationManagement.onClose,
        sessionSnapshot: sessionSnapshot,
        isHealthPlan: isHealthPlan,
      },
    },
    specialized_care_navigator: {
      component: SpecializedCareNavigationFlyout,
      props: {
        callback: handleFlyoutOpenSpecializedCNAppointmentModal,
        isAlsoAvailableTile: true,
        isOpen: disclosures.specializedCareNavigation.isOpen,
        onClose: disclosures.specializedCareNavigation.onClose,
      },
    },
    substance_use: {
      component: SubstanceUseFlyout,
      props: {
        callback: sudZendeskMutation,
        isAlsoAvailableTile: true,
        isOpen: disclosures.substanceUse.isOpen,
        onClose: disclosures.substanceUse.onClose,
      },
    },
    therapist_follow_up: {
      component: TherapyFlyout,
      props: {
        isAlsoAvailableTile: true,
        isOpen: disclosures.therapy.isOpen,
        onClose: disclosures.therapy.onClose,
        sessionSnapshot: sessionSnapshot,
      },
    },
    tobacco_cessation: {
      component: TobaccoCessationFlyout,
      props: {
        callback: tobaccoCtaCallBack,
        isOpen: disclosures.tobaccoCessation.isOpen,
        onClose: disclosures.tobaccoCessation.onClose,
      },
    },
  };

  // Don't render items coming from rotom if there's no config for them
  const availableItemsWithConfigs = availableItems.filter(
    (item) => alsoAvailableSectionConfig[item.care_item_name],
  );

  return (
    <section>
      <Box>
        {!availableCareItems || availableCareItems.length === 0 ? (
          <Spinner speed="1s" size="xl" />
        ) : (
          <>
            <Carousel gap={0} CarouselHeading={CarouselHeading}>
              {availableItemsWithConfigs.map((item) => (
                <Flex
                  key={item.care_item_name}
                  justifyContent="space-between"
                  flexDirection="column"
                  rounded={5}
                  flex={1}
                  p={2}
                  m={4}
                >
                  <AlsoAvailableTile
                    dataCy={item.care_item_name}
                    {...alsoAvailableSectionConfig[item.care_item_name]}
                  />
                </Flex>
              ))}
            </Carousel>
          </>
        )}
      </Box>
      {availableItems?.map((item) => {
        if (flyoutMap[item.care_item_name]) {
          const { component: FlyoutComponent, props: flyoutProps } =
            flyoutMap[item.care_item_name];

          return (
            <FlyoutComponent
              key={`${item.care_item_name}_flyout`}
              {...flyoutProps}
            />
          );
        }
      })}
      <MobileQrCode
        isOpen={isOpen}
        onClose={() => closeDownloadMobileModal(onClose, t)}
        url={DOWNLOAD_APP_URL()}
        header={t("homepage:alsoAvailableSection.mobileQrCode.header")}
        text={t("homepage:alsoAvailableSection.mobileQrCode.text")}
        altText={t("homepage:alsoAvailableSection.mobileQrCode.altText")}
      />
      <CareNavigatorAvailabilityModal
        onOpen={openCNScheduleModal}
        isOpen={isCNScheduleModalOpen}
        onClose={closeCNScheduleModal}
        preventAutoOpen
      />
    </section>
  );
};

const mapStateToProps = ({ global: { showGlobalExperience } }) => ({
  showGlobalExperience,
});

export default connect(mapStateToProps, { openModal, addNotification })(
  AlsoAvailableSection,
);

import { Flex, SimpleGrid, Button } from "@springcare/sh-component-library";
import ParticipantTile from "components/templates/SHSessionRoom/components/ParticipantTile/ParticipantTile";
import { TrackType } from "components/templates/SHSessionRoom/types";
import { useSessionTracks } from "components/templates/SHSessionRoom/hooks/useSessionTracks";
import { useLocalParticipant } from "@livekit/components-react";
import { useSessionScreenSharingMetadata } from "context/SessionRoomContext";

interface ParticipantTilesProps {
  isMobile: boolean;
  drawerWidth: number;
}

interface ParticipantLayoutProps {
  isMobile: boolean;
  localTrack: TrackType;
  remoteTracks: TrackType[];
  drawerWidth: number;
}

const ASPECT_RATIO_16_9 = 16 / 9; //Used to dynamically calculate width
const SECONDARY_MAX_HEIGHT = "16vh"; //Used for smaller participant tiles styling
// NOTE: 48px = 24px margin on each side of screen
const DEFAULT_MAX_WIDTH = `calc(100vw - 48px)`;

const OnlyLocalParticipantLayout = ({ isMobile, localTrack, drawerWidth }) => {
  const maxWidth = `calc(${DEFAULT_MAX_WIDTH} - ${drawerWidth}px)`;
  const maxHeight = "90vh";

  return (
    <Flex justify="center">
      <ParticipantTile
        key={localTrack?.participant?.sid}
        isMobile={isMobile}
        track={localTrack}
        maxHeight={maxHeight}
        maxWidth={maxWidth}
        isMirrorVideo={true}
      />
    </Flex>
  );
};

const TwoParticipantsLayout = ({
  isMobile,
  localTrack,
  remoteTracks,
  drawerWidth,
}: ParticipantLayoutProps) => {
  const maxWidth = `calc(${DEFAULT_MAX_WIDTH} - ${drawerWidth}px)`;
  const maxHeight = "90vh";
  const secondaryMaxWidth = `calc(${ASPECT_RATIO_16_9} * ${SECONDARY_MAX_HEIGHT})`;

  return (
    <Flex justify="center">
      <ParticipantTile
        key={remoteTracks[0]?.participant?.sid}
        isMobile={isMobile}
        track={remoteTracks[0]}
        maxHeight={maxHeight}
        maxWidth={maxWidth}
      />
      <Flex
        maxWidth={maxWidth}
        width={`calc(${maxHeight} * ${ASPECT_RATIO_16_9})`}
        align="end"
        direction="column"
        pos="absolute"
        mt="v-12"
        me="v-16"
      >
        <ParticipantTile
          key={localTrack?.participant?.sid}
          isMobile={isMobile}
          track={localTrack}
          isMirrorVideo={true}
          maxHeight={SECONDARY_MAX_HEIGHT}
          maxWidth={secondaryMaxWidth}
        />
      </Flex>
    </Flex>
  );
};

const TwoParticipantsMobileLayout = ({
  isMobile,
  localTrack,
  remoteTracks,
  drawerWidth,
}: ParticipantLayoutProps) => {
  const maxWidth = `calc(${DEFAULT_MAX_WIDTH} - ${drawerWidth}px)`;
  const maxHeight = "40vh"; // Adjusted to ensure both participants fit on screen

  return (
    <Flex direction="column" gap="v-16">
      <ParticipantTile
        key={remoteTracks[0]?.participant?.sid}
        isMobile={isMobile}
        track={remoteTracks[0]}
        maxHeight={maxHeight}
        maxWidth={maxWidth}
      />
      <ParticipantTile
        key={localTrack?.participant?.sid}
        isMobile={isMobile}
        track={localTrack}
        isMirrorVideo={true}
        maxHeight={maxHeight}
        maxWidth={maxWidth}
      />
    </Flex>
  );
};

const GroupLayout = ({
  isMobile,
  localTrack,
  remoteTracks,
  drawerWidth,
}: ParticipantLayoutProps) => {
  const totalParticipants = remoteTracks.length + 1;
  const numberOfColumns = Math.ceil(Math.sqrt(totalParticipants));
  const numberOfRows = Math.ceil(totalParticipants / numberOfColumns);

  const maxWidth = `calc((${DEFAULT_MAX_WIDTH} - ${drawerWidth}px) / ${numberOfColumns})`;
  const maxHeight = `calc(90vh / ${numberOfRows})`;

  return (
    <Flex justify="center">
      <SimpleGrid columns={numberOfColumns} spacing={10}>
        <ParticipantTile
          key={localTrack?.participant?.sid}
          isMobile={isMobile}
          track={localTrack}
          isMirrorVideo={true}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
        />
        {remoteTracks.map((track) => (
          <ParticipantTile
            key={track?.participant?.sid}
            isMobile={isMobile}
            track={track}
            maxHeight={maxHeight}
            maxWidth={maxWidth}
          />
        ))}
      </SimpleGrid>
    </Flex>
  );
};

const ScreensharingLayout = ({
  isMobile,
  localTrack,
  remoteTracks,
  screensharingTracks,
  drawerWidth,
}) => {
  const { localParticipant } = useLocalParticipant();
  const isScreenShareEnabled = localParticipant.isScreenShareEnabled;
  const buttonHeight = isScreenShareEnabled ? "48px" : "0px";
  const maxWidth = `calc(${DEFAULT_MAX_WIDTH} - ${drawerWidth}px)`;
  const secondaryMaxWidth = `calc(${ASPECT_RATIO_16_9} * ${SECONDARY_MAX_HEIGHT})`;
  const maxHeight = `calc(90vh - ${SECONDARY_MAX_HEIGHT} - ${buttonHeight})`;

  return (
    <Flex justify="center" direction="column" gap="v-8">
      <Flex justify="center" gap="v-8">
        <ParticipantTile
          key={localTrack?.participant?.sid}
          isMobile={isMobile}
          track={localTrack}
          isMirrorVideo={true}
          maxHeight={SECONDARY_MAX_HEIGHT}
          maxWidth={secondaryMaxWidth}
        />
        {remoteTracks.map((track) => (
          <ParticipantTile
            key={track?.participant?.sid}
            isMobile={isMobile}
            track={track}
            maxHeight={SECONDARY_MAX_HEIGHT}
            maxWidth={secondaryMaxWidth}
          />
        ))}
      </Flex>
      <Flex
        maxWidth={maxWidth}
        width={`calc(${maxHeight} * ${ASPECT_RATIO_16_9})`}
      >
        <ParticipantTile
          key={screensharingTracks[0]?.participant?.sid}
          isMobile={isMobile}
          track={screensharingTracks[0]}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
        />
      </Flex>
      {isScreenShareEnabled && (
        <Button
          w="fit-content"
          variant="high-emphasis"
          alignSelf="center"
          onClick={() => {
            localParticipant.setScreenShareEnabled(false);
          }}
        >
          Stop presenting
        </Button>
      )}
    </Flex>
  );
};

const SessionRoomGrid = ({ isMobile, drawerWidth }: ParticipantTilesProps) => {
  const { localTrack, remoteTracks, screenshareTracks } = useSessionTracks();
  const { setIsRemoteParticipantSharingScreen } =
    useSessionScreenSharingMetadata();
  const { localParticipant } = useLocalParticipant();

  const hasScreensharingTrack = screenshareTracks.length > 0;
  setIsRemoteParticipantSharingScreen(
    // if there's a screensharing track but the localParticipant is not sharing, then a remote participant must be sharing
    hasScreensharingTrack && !localParticipant.isScreenShareEnabled,
  );
  const hasOnlyLocalParticipant =
    localTrack && remoteTracks?.length === 0 && !hasScreensharingTrack;
  const hasTwoParticipants =
    localTrack && remoteTracks?.length === 1 && !hasScreensharingTrack;
  const hasMultipleParticipants =
    localTrack && remoteTracks?.length > 1 && !hasScreensharingTrack;

  return (
    <Flex justify="center" height="100%" align="center">
      {hasScreensharingTrack && (
        <ScreensharingLayout
          isMobile={isMobile}
          localTrack={localTrack}
          remoteTracks={remoteTracks}
          screensharingTracks={screenshareTracks}
          drawerWidth={drawerWidth}
        />
      )}
      {hasOnlyLocalParticipant && (
        <OnlyLocalParticipantLayout
          isMobile={isMobile}
          localTrack={localTrack}
          drawerWidth={drawerWidth}
        />
      )}
      {hasTwoParticipants && !isMobile && (
        <TwoParticipantsLayout
          isMobile={isMobile}
          localTrack={localTrack}
          remoteTracks={remoteTracks}
          drawerWidth={drawerWidth}
        />
      )}
      {hasTwoParticipants && isMobile && (
        <TwoParticipantsMobileLayout
          isMobile={isMobile}
          localTrack={localTrack}
          remoteTracks={remoteTracks}
          drawerWidth={drawerWidth}
        />
      )}
      {hasMultipleParticipants && (
        <GroupLayout
          isMobile={isMobile}
          localTrack={localTrack}
          remoteTracks={remoteTracks}
          drawerWidth={drawerWidth}
        />
      )}
    </Flex>
  );
};

export default SessionRoomGrid;

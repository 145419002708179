import React, { useState } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  VLogOutIcon,
  VArrowRightIcon,
  VXIcon,
  Tooltip,
} from "@springcare/sh-component-library";
import {
  useSessionConnectStatus,
  useSessionParticipantType,
} from "context/SessionRoomContext";
import { ParticipantType } from "components/templates/SHSessionRoom/types";

export const ExitButton = () => {
  const { handleDisconnect } = useSessionConnectStatus();
  const { participantType } = useSessionParticipantType();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Menu>
      <Tooltip label="Leave" aria-label="Leave">
        <MenuButton
          as={IconButton}
          icon={VLogOutIcon}
          aria-haspopup="true"
          aria-expanded={isOpen}
          aria-label={
            participantType === ParticipantType.Provider
              ? "Options to leave or end the session"
              : "Options to leave or stay in the session"
          }
          onClick={() => setIsOpen(!isOpen)}
          variant="high-emphasis"
          colorScheme="negative"
          _focus={{}} // handle styling issues that probably need to be fixed in the component-library
        >
          Exit
        </MenuButton>
      </Tooltip>
      <MenuList zIndex={10}>
        <MenuItem
          icon={<VArrowRightIcon />}
          onClick={handleDisconnect}
          aria-label="Leave the session"
        >
          Leave session
        </MenuItem>
        {participantType === ParticipantType.Provider ? (
          <MenuItem
            icon={<VXIcon />}
            onClick={handleDisconnect}
            aria-label="End the session for all participants"
          >
            End session for all
          </MenuItem>
        ) : (
          <MenuItem
            icon={<VXIcon />}
            onClick={() => setIsOpen(!isOpen)}
            aria-label="Stay in session"
          >
            Stay in session
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};

export default ExitButton;

ExitButton.displayName = "ExitButton";

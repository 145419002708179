import { Start, Center, End } from "./VideoCallControls/Group";
import { ToggleButton } from "./VideoCallControls/ToggleButton";
import { DeviceMenuChevron } from "./VideoCallControls/DeviceMenuChevron";
import { ExitButton } from "./VideoCallControls/ExitButton";
import { AdmitParticipantPopover } from "./VideoCallControls/AdmitParticipantPopover";
import {
  ToggleButtonTypes,
  DeviceTypes,
  ParticipantType,
} from "components/templates/SHSessionRoom/types";
import { Flex } from "@springcare/sh-component-library";
import { useSessionParticipantType } from "context/SessionRoomContext";

interface VideoCallControlsProps {
  isMobile: boolean;
}

const DesktopControls = () => (
  <>
    <Start>
      <ToggleButton type={ToggleButtonTypes.Microphone} />
      <DeviceMenuChevron
        types={[DeviceTypes.AudioInput, DeviceTypes.AudioOutput]}
      />
      <ToggleButton type={ToggleButtonTypes.Camera} />
      <DeviceMenuChevron types={[DeviceTypes.VideoInput]} />
    </Start>
    <Center>
      <ToggleButton type={ToggleButtonTypes.Participants} />
      <ToggleButton type={ToggleButtonTypes.Chat} />
      <ToggleButton type={ToggleButtonTypes.Screensharing} />
    </Center>
    <End>
      <ExitButton />
    </End>
  </>
);

const MobileControls = () => (
  <Center>
    <ExitButton />
    <ToggleButton type={ToggleButtonTypes.Microphone} />
    <ToggleButton type={ToggleButtonTypes.Camera} />
    <ToggleButton type={ToggleButtonTypes.Participants} />
    <ToggleButton type={ToggleButtonTypes.Chat} />
  </Center>
);

export const VideoCallControls = ({ isMobile }: VideoCallControlsProps) => {
  const { participantType } = useSessionParticipantType();

  return (
    <Flex
      justifyContent="center"
      width="100%"
      maxWidth={`calc(90vh * (16/9))`}
      direction="column"
    >
      {participantType === ParticipantType.Provider && (
        <AdmitParticipantPopover />
      )}
      <Flex
        position="relative"
        justifyContent={isMobile ? "center" : "space-between"}
        width="100%"
      >
        {isMobile ? <MobileControls /> : <DesktopControls />}
      </Flex>
    </Flex>
  );
};

export default VideoCallControls;
